html, body{
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
  background-color: #0A2232;
  background-image: url('images/4_b.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}